import { contactEmail, installSection } from '../mock';
import InstallCommand from './InstallCommand';

const InstallSection = () => {
  return (
    <div
      className="2xl:w-949 2xl:mx-auto"
      id="install"
    >
      <code className="text-center text-purple-secondary font-medium text-lg md:text-base lg:text-lg 2xl:text-22">
        {installSection.sectionName}
      </code>
      <h1 className="pb-64 text-gradient text-40 leading-42 font-semibold text-center pt-38 md:pt-10 md:text-46 md:leading-56 lg:text-52 lg:leading-56 lg:pt-38 2xl:pt-8">
        {installSection.title}
      </h1>
      <div className="md:w-481 md:m-auto lg:w-886 flex justify-center">
        <InstallCommand
          context="install"
          className="w-full"
        />
      </div>
      <div className="flex flex-col pt-64 font-medium text-22 leading-35 text-purple-default md:flex-row md:justify-center lg:text-25 2xl:text-29 2xl:py-64">
        <a
          href={`mailto:${contactEmail}`}
          className="text-gradient  lg:text-25 2xl:text-29"
        >
          {installSection.linkText}
        </a>
        <span className="md:pl-2">{installSection.contactUs}</span>
      </div>
    </div>
  );
};

export default InstallSection;
