import { ExamplesSection, Feature, QuickstartSection } from './interfaces';
import { ReactComponent as ArrowUpRight } from './components/icons/arrow-up-right.svg';
import { ReactComponent as Control } from './components/icons/control.svg';
import { ReactComponent as Evolve } from './components/icons/evolve.svg';
import { ReactComponent as Github } from './components/icons/github.svg';
import { ReactComponent as Nnaisense } from './components/icons/nnaisense.svg';
import { ReactComponent as Slack } from './components/icons/slack.svg';
import { ReactComponent as Solve } from './components/icons/puzzle.svg';
import { ReactComponent as Train } from './components/icons/train.svg';

export const externalLinks = {
  docs: {
    title: 'Docs',
    anchor: 'https://docs.evotorch.ai',
    external: true,
    icon: <ArrowUpRight />,
    context: ['menu', 'hero', 'install'],
  },
  slack: {
    title: 'Slack',
    anchor:
      'https://join.slack.com/t/evotorch/shared_invite/zt-1fhjgansw-ssHnhkq6NnRyvwMAUl~htA',
    external: true,
    icon: <Slack />,
    context: ['menu', 'install'],
  },
  github: {
    title: 'GitHub',
    anchor: 'https://github.com/nnaisense/evotorch',
    external: true,
    icon: <Github />,
    context: ['menu', 'hero', 'install'],
  },
  nnaisense: {
    title: 'NNAISENSE',
    anchor: 'https://nnaisense.com',
    external: true,
    icon: <Nnaisense />,
    context: ['menu'],
  },
};

export const navbar = [
  { title: 'How To', anchor: 'how-to' },
  { title: 'Examples', anchor: 'examples' },
  { title: 'Features', anchor: 'features' },
  { title: 'Install', anchor: 'install' },
];

export const heroSection = {
  title: 'Next-generation Evolutionary Search, Learning & Planning',
  description:
    'EvoTorch\u2122 is designed to accelerate research and applications of Evolutionary Algorithms, with dedicated support for NeuroEvolution.',
};

export const installCommand = {
  command: 'pip install evotorch',
  cmdBtn: 'Copy',
};

export const quickstartSections: QuickstartSection[] = [
  {
    key: 'prvy',
    title: 'Import libraries',
    description: 'EvoTorch provides SOTA algorithms and utilities.',
    code: `import torch
from evotorch import Problem
from evotorch.algorithms import SNES
from evotorch.logging import StdOutLogger`,
  },
  {
    key: 'druhy',

    title: 'Define an objective',
    description: 'Use PyTorch to define a function to optimize',
    code: `def norm(x: torch.Tensor) -> torch.Tensor:
  return torch.linalg.norm(x, dim=-1)`,
  },
  {
    key: 'treti',

    title: 'Define a problem',
    description: 'Instantiate the problem and select device(s) to use',
    code: `problem = Problem(
  "min",
  norm,
  initial_bounds=(-10.0, 10.0),
  solution_length=100,
  vectorized=True,
  # device="cuda",  # Enable for GPU support
)`,
  },
  {
    key: 'stvrty',

    title: 'Run the evolutionary algorithm',
    description:'', // 'Use the specified devices and log the results',
    code: `searcher = SNES(problem, popsize=1000, stdev_init=10.0)
_ = StdOutLogger(searcher, interval=50)

searcher.run(num_generations=1000)`,
  },
];

export const examplesSection: ExamplesSection = {
  sectionName: '// Examples',
  title: 'EvoTorch reduces the boiler-plate and lets you focus on solving problems at scale.',
  tabs: [
    {
      title: 'GPU acceleration',
      code: `import torch
from evotorch.algorithms import SNES
from evotorch.logging import StdOutLogger
from evotorch import Problem

# Minimize the Lennard-Jones atom cluster potential
def pairwise_distances(positions: torch.Tensor) -> torch.Tensor:
    positions = positions.view(positions.shape[0], -1, 3)
    deltas = positions.unsqueeze(2) - positions.unsqueeze(1)
    distances = torch.norm(deltas, dim=-1)
    return distances


def cluster_potential(positions: torch.Tensor) -> torch.Tensor:
    distances = pairwise_distances(positions)
    pairwise_cost = (1 / distances).pow(12) - (1 / distances).pow(6.0)
    ut_pairwise_cost = torch.triu(pairwise_cost, diagonal=1)
    potential = 4 * ut_pairwise_cost.sum(dim=(1, 2))
    return potential


problem = Problem(
    "min",
    cluster_potential,
    initial_bounds=(-1e-12, 1e-12),
    device="cuda:0" if torch.cuda.is_available() else "cpu",
    solution_length=150,
    # Evaluation is vectorized
    vectorized=True,
    # Higher-than-default precision
    dtype=torch.float64,
)

searcher = SNES(problem, popsize=1000, stdev_init=0.01)
logger = StdOutLogger(searcher, interval=100)

searcher.run(5000)`,
      videoType: 'lennard',
    },
    {
      title: 'Multiple Objectives',
      code: `import torch
from evotorch import Problem
from evotorch.algorithms import SteadyStateGA
from evotorch.operators import (
    SimulatedBinaryCrossOver,
    GaussianMutation,
)
from evotorch.logging import StdOutLogger

# Kursawe function with two conflicting objectives
def kursawe(x: torch.Tensor) -> torch.Tensor:
    f1 = torch.sum(
        -10 * torch.exp(
            -0.2 * torch.sqrt(x[:, 0:2] ** 2.0 + x[:, 1:3] ** 2.0)
        ),
        dim=-1,
    )
    f2 = torch.sum(
        (torch.abs(x) ** 0.8) + (5 * torch.sin(x ** 3)),
        dim=-1,
    )
    fitnesses = torch.stack([f1, f2], dim=-1)
    return fitnesses

prob = Problem(
    # Two objectives, both minimization
    ["min", "min"],
    kursawe,
    initial_bounds=(-5.0, 5.0),
    solution_length=3,
    vectorized=True,
)

# Works like NSGA-II for multiple objectives
ga = SteadyStateGA(prob, popsize=200)
ga.use(
    SimulatedBinaryCrossOver(
        prob,
        tournament_size=4,
        cross_over_rate=1.0,
        eta=8,
    )
)
ga.use(GaussianMutation(prob, stdev=0.03))
logger = StdOutLogger(ga)

ga.run(100)`,
      videoType: 'kursawe',
    },
    {
      title: 'Parallelized RL',
      code: `from evotorch.algorithms import PGPE
from evotorch.logging import StdOutLogger
from evotorch.neuroevolution import GymNE

# Specialized Problem class for RL
problem = GymNE(
    env_name="Humanoid-v4",
    # Linear policy
    network="Linear(obs_length, act_length)",
    observation_normalization=True,
    decrease_rewards_by=5.0,
    # Use all available CPU cores
    num_actors="max",
)

searcher = PGPE(
    problem,
    popsize=200,
    center_learning_rate=0.01125,
    stdev_learning_rate=0.1,
    optimizer_config={"max_speed": 0.015},
    radius_init=0.27,
    num_interactions=150000,
    popsize_max=3200,
)
logger = StdOutLogger(searcher)
searcher.run(500)

population_center = searcher.status["center"]
policy = problem.to_policy(population_center)
problem.visualize(policy)`,
      videoType: 'pybullet',
    },
  ],
};


export const featuresSection: Feature[] = [
  {
    title: 'Scalable Parallelism',
    description:
      "Evolutionary Algorithms and Neural Networks both thrive at scale. That's why EvoTorch lets you easily unleash experiments on multiple CPU nodes, GPU nodes, or both, building on top of Ray and PyTorch.",
  },
  {
    title: 'Flexibility',
    boxes: [
      {
        icon: <Evolve />,
        title: 'Search',
        description: 'Explore the space of images, programs, designs, or anything else',
      },
      {
        icon: <Solve />,
        title: 'Optimize',
        description: 'Solve large-scale single/multiobjective engineering optimization problems',
      },
      {
        icon: <Train />,
        title: 'Plan',
        description: 'Implement fast Model Predictive Control with learned dynamics models',
      },
      {
        icon: <Control />,
        title: 'Control',
        description: 'Evolve high-performance neural controllers for complex reinforcement learning tasks (neuroevolution)',
      },
    ],
  },
  {
    title: 'Enterprise Support',
    description:
      'NNAISENSE offers paid support including customization for your preferred domain-specific problems, compute environments, production deployments and maintenance of trained solutions.',
  },
];

export const installSection = {
  sectionName: '// Install',
  title: 'Start using EvoTorch',
  contactUs: 'for Enterprise Support.',
  linkText: 'Contact us',
};

export const contactEmail = 'evotorch@nnaisense.com';
