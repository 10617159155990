import kursawe from 'src/visualizations/kursawe.mov';
import lennard from 'src/visualizations/lennard.mov';
import pybullet from 'src/visualizations/pybullet.mp4';

interface Props {
  className?: string;
  type: 'kursawe' | 'lennard' | 'pybullet';
}

const videos = {
  kursawe,
  lennard,
  pybullet,
};

const VideoSection: React.FC<Props> = ({ className, type }) => {
  return (
    <div className={`hover:cursor-pointer${className ? ' ' + className : ''}`}>
      <video
        controls
        width="750"
        height="500"
        loop
        autoPlay
        muted
        className="rounded-lg"
      >
        <source
          src={videos[type]}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default VideoSection;
