import { featuresSection } from '../mock';

const FeaturesSection = () => {
  return (
    <>
      <code
        className="flex self-start text-purple-secondary font-medium text-lg md:text-base lg:text-lg 2xl:ml-0 2xl:text-22"
        id="features"
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      >
        // Features
      </code>
      <div className="text-left mt-8 space-y-120 md:mt-10 md:space-y-200 2xl:mt-64">
        {featuresSection.map((feature) => (
          <div
            className="md:flex md:flex-row md:space-x-100 lg:space-x-110 2xl:space-x-233"
            key={feature.title}
          >
            <h1 className="text-gradient font-bold text-31 leading-37 mb-10 md:mb-0 lg:text-4xl lg:leading-43 lg:w-250 2xl:w-1/5 2xl:text-43 2xl:leading-51">
              {feature.title}
            </h1>
            {feature.description && (
              <p className="text-purple-default text-17 leading-27 md:text-18 md:leading-29 md:font-normal 2xl:w-3/5 2xl:text-24 2xl:leading-38">
                {feature.description}
              </p>
            )}
            {feature.boxes?.length && (
              <div className="space-y-10 md:space-y-55 lg:grid lg:grid-cols-2 lg:gap-x-91 lg:gap-y-80 lg:items-start lg:space-y-0">
                {feature.boxes.map((box) => (
                  <div
                    className="flex items-start lg:flex-col lg:max-w-360"
                    key={box.title}
                  >
                    <div className="mr-6 lg:m-0">{box.icon}</div>
                    <div className="lg:max-w-260 2xl:max-w-360">
                      <h3 className="font-bold text-22 text-purple-default leading-3 md:leading-27 lg:text-2xl lg:py-6">
                        {box.title}
                      </h3>
                      <p className="text-17 leading-27 text-purple-default mt-2 md:leading-29 lg:text-lg lg:m-0 2xl:text-24">
                        {box.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default FeaturesSection;
