import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
}

const Section: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`relative max-w-1680 mx-auto${className ? ' ' + className : ''}`}>
      {children}
    </div>
  );
};

export default Section;
