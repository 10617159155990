import { ReactNode } from 'react';

interface Props {
  className?: string;
  children?: ReactNode;
  id?: string;
}

const Content: React.FC<Props> = ({ children, className, id }) => {
  return (
    <div
      className={`my-0 mx-4 md:mx-12 lg:mx-8 2xl:w-1280 2xl:mx-auto${
        className ? ' ' + className : ''
      }`}
      id={id}
    >
      {children}
    </div>
  );
};

export default Content;
