import { externalLinks, installCommand } from '../mock';
import { copyTextToClipboard } from '../utils';
import { ReactComponent as Copy } from './icons/copy.svg';

interface Props {
  className?: string;
  context: 'hero' | 'install';
}

const InstallCommand: React.FC<Props> = ({ className, context }) => {
  return (
    <div className={`lg:flex lg:flex-row justify-evenly${className ? ' ' + className : ''}`}>
      <div className="bg-black-dark rounded-xl flex justify-between border-black-dark border-4 lg:min-w-408 2xl:min-w-528">
        <code className="text-beige py-14px pl-3 text-lg leading-18 md:py-13 md:pl-6 md:text-xl md:leading-5 lg:pl-6 lg:text-xl lg:py-3 2xl:text-22 2xl:pl-6 2xl:leading-22 2xl:py-14px">
          {installCommand.command}
        </code>
        <span
          className="hover:cursor-pointer rounded-tr-lg rounded-br-lg flex items-center justify-center bg-black-light w-60 hover-magenta-lighter-20 md:w-auto md:px-22 md:py-7px lg:py-10px lg:pl-2 2xl:py-3 2xl:pl-22"
          onClick={() => copyTextToClipboard(installCommand.command)}
        >
          <Copy className="text-purple-outline" />
          <span className="hidden lg:inline-block ml-11px text-purple-outline font-medium text-lg 2xl:text-xl">
            Copy
          </span>
        </span>
      </div>
      <div className="flex space-x-4 mt-4 lg:ml-5 lg:mt-0 2xl:w-full">
        {Object.values(externalLinks)
          .filter((link) => link.context.includes(context))
          .map((link) => (
            <a
              className={`group hover:cursor-pointer bg-purple-light hover:bg-purple-80 border-2 border-purple-outline rounded-xl flex py-13 align-middle items-center justify-center text-purple-secondary font-semibold text-base md:min-w-119 2xl:text-xl lg:py-0 2xl:min-w-140 w-full lg:w-140`}
              key={link.title}
              href={link.anchor}
              target="_blank"
              rel="noreferrer"
            >
              {link.title}
              <span className="pl-2 group-hover:text-purple-primary">{link.icon}</span>
            </a>
          ))}
      </div>
    </div>
  );
};

export default InstallCommand;
