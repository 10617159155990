import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Section from './components/Section';
import Content from './components/Content';
import ExamplesSection from './components/ExamplesSection';
import EvolutionSection from './components/EvolutionSection';
import FeaturesSection from './components/FeaturesSection';
import InstallSection from './components/InstallSection';
import MadeBy from './components/MadeBy';
import { ReactComponent as ArrowTop } from './components/icons/arrow-top.svg';

function App() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);

    const originUrl = window.location.origin;
    window.history.pushState('', '', originUrl);
  };

  return (
    <div className="App bg-center bg-beige bg-terrain-pattern bg-cover">
      <div
        className="fixed bg-beige mb-5 mr-5 cursor-pointer z-50 p-3 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg hover:bg-purple-80 transition duration-150 ease-in-out bottom-0 right-0 lg:visible"
        onClick={scrollToTop}
      >
        <ArrowTop className="w-5 h-5 text-purple-secondary" />
      </div>
      <Section>
        <Navbar />
      </Section>

      <Section>
        <Content className="pt-126 pb-120 md:pt-146 md:pb-222 lg:pb-300 lg:pt-128 2xl:pb-320">
          <HeroSection />
        </Content>
      </Section>

      <Section>
        <Content className="md:pb-280 lg:pb-280 lg:pt-0">
          <EvolutionSection />
        </Content>
      </Section>

      <Section>
        <ExamplesSection />
      </Section>

      <Section>
        <Content className="pt-240 lg:pb-280 lg:pt-260 2xl:pb-320">
          <FeaturesSection />
        </Content>
      </Section>

      <Section>
        <Content className="pt-240 pb-20 md:pb-64 lg:pb-64 lg:pt-0 2xl:pb-0">
          <InstallSection />
        </Content>
      </Section>

      <Section>
        <Content className="pb-100 md:pb-144 md:pt-0 lg:pb-164 lg:pt-0 2xl:pb-320">
          <MadeBy underline />
        </Content>
      </Section>
    </div>
  );
}

export default App;
