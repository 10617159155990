import { useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nightOwl as style } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { examplesSection } from '../mock';
import { copyTextToClipboard } from '../utils';
import Content from './Content';
import VideoSection from './VideoSection';
import { ReactComponent as ChevronDown } from './icons/chevron-down.svg';

const ExamplesSection = () => {
  const [openTab, setOpenTab] = useState(0);
  const [showCode, setShowCode] = useState(true);
  const [copied, setCopied] = useState(false);
  const [showTabs, setShowTabs] = useState(false);

  const handleCopy = (text: string) => {
    copyTextToClipboard(text);
    setCopied(true);

    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setShowCode(true);
      }
    }

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Content
        className="pt-240 pb-20 md:pt-0 md:pb-100 lg:pb-20 lg:pt-0 2xl:pb-100"
        id="examples"
      >
        <code className="text-center text-purple-secondary font-medium text-lg md:text-base lg:text-lg 2xl:text-22">
          {examplesSection.sectionName}
        </code>
        <h1 className="text-gradient font-semibold text-40 leading-42 mt-8 md:text-46 md:leading-48 lg:text-52 lg:leading-54 2xl:text-60 2xl:leading-63 lg:w-846 lg:mx-auto">
          {examplesSection.title}
        </h1>
      </Content>

      <div className="flex flex-wrap md:mx-12 lg:mx-8">
        <div className="w-full relative 2xl:w-1280 2xl:m-auto">
          <div className="bg-black-dark p-2 md:hidden">
            <div
              className={`p-4 justify-between text-sm font-semibold flex items-center bg-black-medium rounded-md text-purple-outline`}
              onClick={() => setShowTabs(!showTabs)}
            >
              {examplesSection.tabs[openTab].title}
              <ChevronDown className={`md:hidden w-6 ml-4${showTabs ? ' rotate-180' : ''}`} />
            </div>
          </div>
          <ul
            className={`flex mb-0 list-none flex-wrap flex-row flex-1 mx-2 py-2 rounded-md bg-black-medium border-b border-purple-secondary md:border-none md:m-0 md:flex-no-wrap md:space-y-0 md:space-x-2 md:bg-black-dark md:p-9px md:rounded-b-none md:rounded-tl-xl md:rounded-tr-xl lg:p-2 2xl:pb-4 2xl:border-b 2xl:border-solid 2xl:border-black-medium ${
              showTabs ? 'absolute top-70 z-20' : 'hidden md:flex'
            }`}
            role="tablist"
          >
            {examplesSection.tabs.map((tab, index) => (
              <li
                className={`flex-col w-full text-center flex md:flex-1 pl-4 md:w-0 relative md:pl-0 ${
                  openTab === index
                    ? ' underline-gradient before:absolute before:left-0 before:top-20% before:w-3px before:h-8 before:bg-purple-light md:before:hidden'
                    : ''
                }`}
                key={index}
              >
                <span
                  className={`w-full ${
                    openTab === index
                      ? ' md:pb-2px bg-gradient-to-r from-gradient-from to-gradient-to rounded-md'
                      : ''
                  }`}
                >
                  <a
                    className={`bg-black-medium text-sm text-left font-semibold block  w-full md:rounded-md md:text-sm md:px-3 md:my-0 md:text-center lg:text-base lg:px-4 lg:flex lg:min-h-80 lg:items-center lg:justify-around 2xl:text-18 2xl:leading-21 lg:py-4 2xl:min-h-auto py-3  2xl:py-4 ${
                      openTab === index
                        ? 'text-beige md:bg-black-medium md:rounded-md'
                        : 'text-purple-outline md:bg-black-dark hover:bg-black-medium hover:cursor-pointer'
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(index);
                      setShowTabs(false);
                    }}
                    data-toggle="tab"
                    href={`#link${index}`}
                    role="tablist"
                  >
                    {tab.title}
                  </a>
                </span>
              </li>
            ))}
          </ul>
          <div className="relative flex flex-col bg-white w-full mb-6 shadow-lg md:shadow-none md:mb-0">
            <div className="flex-auto">
              <div className="tab-content tab-space">
                {examplesSection.tabs.map((tab, index) => (
                  <div
                    className={`text-xs rounded-none w-full bg-black-dark lg:text-sm rounded-bl-xl rounded-br-xl flex justify-items-start relative${
                      openTab === index ? ' block' : ' hidden'
                    }`}
                    id={`link${index}`}
                    key={index}
                  >
                    <div
                      className={`w-full lg:w-1/2 relative group ${
                        showCode ? ' block' : ' hidden'
                      }`}
                    >
                      <div
                        onClick={() => handleCopy(tab.code)}
                        className="absolute hidden right-3 top-3 p-3 bg-magenta-dark text-beige group-hover:block rounded-lg hover:cursor-pointer hover-magenta-lighter-20"
                      >
                        {copied ? 'Copied!' : 'Copy'}
                      </div>
                      <SyntaxHighlighter
                        language="python"
                        style={style}
                        showLineNumbers={true}
                        customStyle={{
                          borderRadius: 'none',
                          margin: 0,
                          borderBottomLeftRadius: '12px',
                          borderBottomRightRadius: '12px',
                          background: '#33313F',
                          overflowY: 'auto',
                          height: '680px',
                        }}
                      >
                        {tab.code}
                      </SyntaxHighlighter>
                    </div>
                    <div
                      className={`w-full lg:w-1/2 bg-black-dark align-middle flex justify-center items-center rounded-br-xl md:rounded-bl-xl  p-4${
                        !showCode ? ' flex min-h-372' : ' hidden lg:flex'
                      }`}
                    >
                      <VideoSection type={tab.videoType} />
                    </div>
                    <div className="lg:hidden absolute bottom-25 left-0 right-0 m-auto w-182 bg-black-medium py-9px px-5 flex flex-row items-center text-purple-outline justify-center space-x-10 rounded-full cursor-pointer">
                      <div
                        className={`hover-gradient${showCode ? ' text-gradient' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowCode(true);
                        }}
                      >
                        Code
                      </div>
                      <div
                        className={`hover-gradient${!showCode ? ' text-gradient' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowCode(false);
                        }}
                      >
                        Visualisation
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamplesSection;
