import { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nightOwl as style } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { quickstartSections } from '../mock';
import { copyTextToClipboard } from '../utils';

const EvolutionSection = () => {
  const [copiedBlockIndex, setCopiedBlockIndex] = useState(-1);

  const codeAll = quickstartSections.map((section) => section.code);

  const handleCopy = (index: number, text: string) => {
    copyTextToClipboard(text);
    setCopiedBlockIndex(index);

    const timer = setTimeout(() => {
      setCopiedBlockIndex(-1);
    }, 1000);
    return () => clearTimeout(timer);
  };
  return (
    <>
      <div
        className="relative"
        id="how-to"
      >
        <div className="text-left space-y-120 2xl:space-y-160">
          {quickstartSections.map((section, index) => (
            <div
              className="md:flex md:flex-row md:space-x-10 2xl:space-x-120 the-office"
              key={index}
            >
              <div className="md:w-2/5 2xl:w-320">
                {/* eslint-disable react/jsx-no-comment-textnodes */}
                <code className="text-purple-secondary font-medium text-sm md:text-base lg:text-lg 2xl:text-22">
                  // 0{index + 1}
                </code>
                <h2 className="text-gradient font-bold text-31 leading-31 my-6 md:text-33 md:leading-39 lg:text-4xl 2xl:leading-51 2xl:text-43">
                  {section.title}
                </h2>
                <p className="text-17 leading-27 text-purple-default pb-16 md:pb-0 md:text-lg 2xl:text-22 2xl:font-normal 2xl:leading-38">
                  {section.description}
                </p>
              </div>
              <div className="relative text-xs min-h-325 md:w-3/5 md:min-w-325 md:min-h-0 lg:text-sm 2xl:w-840 2xl:text-base 2xl:h-460 group">
                <div
                  onClick={() => handleCopy(index, section.code)}
                  className="absolute right-3 top-3 p-3 bg-magenta-dark rounded-lg text-beige hidden hover-magenta-lighter-20 hover:cursor-pointer group-hover:block"
                >
                  {copiedBlockIndex === index ? (
                    'Copied!'
                  ) : (
                    <div className="flex items-center">Copy</div>
                  )}
                </div>
                <div className="background-container">
                  <SyntaxHighlighter
                    language="python"
                    style={style}
                    showLineNumbers
                    customStyle={{
                      minHeight: '260px',
                      borderRadius: '0.75rem',
                      fontFamily: 'JetBrains Mono, monospace',
                      margin: '0',
                      backgroundColor: '#2A2D3D',
                    }}
                  >
                    {section.code}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EvolutionSection;
