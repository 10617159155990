import { useRef, useState } from 'react';
import { externalLinks, navbar } from '../mock';

import { ReactComponent as Logo } from './icons/logo.svg';
import { ReactComponent as Torch } from './icons/torch.svg';
import { ReactComponent as Crisis } from './icons/crisis.svg';
import { ReactComponent as BurgerMenu } from './icons/burger-menu.svg';

interface Props {
  className?: string;
}

const Navbar: React.FC<Props> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <nav
        className={`z-50 relative${isOpen ? ' bg-lighter' : ''}${className ? ' ' + className : ''}`}
      >
        <div className="w-full mx-auto fixed sm:px-6 lg:px-8 2xl:w-1680">
          <div className="my-4">
            <div className="flex items-center content-end">
              <div className="flex-shrink-0 flex flex-row items-center mr-auto ml-4 py-2 px-3 bg-beige backdrop-blur-28 left-0 bg-opacity-70 rounded-full md:py-1 md:px-3 md:ml-0 lg:py-3 lg:px-4 xl:py-4">
                <span className="hidden pr-10px md:flex">
                  <Torch />
                </span>
                <Logo />
              </div>
              <div className="hidden z-10 px-4 right-0 bg-beige backdrop-blur-28 rounded-full bg-opacity-70 md:flex md:items-center md:py-1 lg:py-0">
                <div className="text-sm md:flex-grow md:flex-row md:flex md:items-center lg:space-x-10">
                  <span className="text-purple-default md:space-x-5 lg:space-x-10 lg:py-3 lg:pl-6">
                    {navbar.map((navItem) => (
                      <a
                        href={`#${navItem.anchor}`}
                        className="font-medium hover:underline lg:text-base text-purple-default"
                        key={navItem.anchor}
                      >
                        {navItem.title}
                      </a>
                    ))}
                  </span>
                  <span className="flex flex-row items-center ml-8 justify-between space-x-5 lg:space-x-7 lg:py-3 text-purple-default">
                    {Object.values(externalLinks).map((navItem) => (
                      <a
                        href={navItem.anchor}
                        className="flex flex-row items-center font-medium hover:underline lg:text-base first:border-l first:border-purple-light first:pl-10"
                        key={navItem.anchor}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {navItem.title === 'Docs' ? (
                          <div className="pr-2">{navItem.title}</div>
                        ) : (
                          ''
                        )}
                        {navItem.icon}
                      </a>
                    ))}
                  </span>
                </div>
              </div>
              <div className="flex z-50 md:hidden fixed right-4 mr-1 mt-1">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-gray-900 inline-flex items-center justify-center p-2 text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white bg-beige bg-opacity-70 z-10 rounded-full backdrop-blur-28"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <BurgerMenu className="block h-6 w-6" />
                  ) : (
                    <Crisis className="block h-6 w-6" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            isOpen ? 'visible' : 'hidden '
          } md:hidden fixed top-55 w-247 bg-beige bg-opacity-90 right-0 rounded-3xl shadow z-50`}
          id="mobile-menu"
        >
          <div
            ref={ref}
            className="p-8 space-y-6 text-left sm:px-3 text-purple-default"
          >
            {navbar.map((navItem) => (
              <a
                href={`#${navItem.anchor}`}
                className="block lg:inline-block font-medium text-2xl"
                onClick={() => setIsOpen(false)}
                key={`menu-${navItem.anchor}`}
              >
                {navItem.title}
              </a>
            ))}
            <div className="border-t flex flex-row border-purple-light pt-27 justify-between">
              {Object.values(externalLinks)
                .filter((link) => link.context.includes('menu'))
                .map((navItem) => (
                  <a
                    href={navItem.anchor}
                    className="flex flex-row items-center"
                    onClick={() => setIsOpen(false)}
                    key={`menu-${navItem.anchor}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {navItem.title === 'Docs' ? <div className="pr-2">{navItem.title}</div> : ''}
                    {navItem.icon}
                  </a>
                ))}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
