interface Props {
  className?: string;
  underline?: boolean;
}

const MadeBy: React.FC<Props> = ({ className, underline = false }) => {
  return (
    <code
      className={`font-medium text-sm text-purple-secondary md:text-base lg:text-18 2xl:text-22${
        className ? ' ' + className : ''
      }`}
      // eslint-disable-next-line react/jsx-no-comment-textnodes
    >
      // Evolved at
      <a
        href="https://nnaisense.com"
        target="_blank"
        className={`uppercase text-gradient pl-2 mr-2 relative${
          underline
            ? ' underline-gradient-no-hover after:h-1px after:absolute after:bottom-0.5 after:left-2 after:m-l-2 after:w-93% cursor-pointer'
            : ''
        }`}
        rel="noreferrer"
      >
        NNAISENSE
      </a>
      in Switzerland
    </code>
  );
};

export default MadeBy;
