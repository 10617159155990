import { heroSection } from '../mock';
import { ReactComponent as ArrowDown } from './icons/arrow-down.svg';
import { ReactComponent as ArrowDownLong } from './icons/arrow-down-long.svg';
import InstallCommand from './InstallCommand';

const HeroSection = () => {
  return (
    <>
      <h1 className="text-53 leading-56 font-semibold text-left text-gradient pb-12 md:text-64 md:leading-67 md:pb-66 lg:text-91 lg:leading-95 lg:pb-51 2xl:text-122 2xl:leading-128">
        {heroSection.title}
      </h1>
      <div className="md:w-full flex flex-col relative">
        <p className="text-22 leading-35 mb-12 text-left text-purple-default font-medium md:w-489 md:self-end md:text-23 md:leading-23 md:mb-46 lg:text-25 lg:w-678 lg:leading-40 lg:mb-38 2xl:text-29 2xl:leading-46 2xl:w-840 2xl:mb-72">
          {heroSection.description}
        </p>
        <ArrowDownLong className="hidden md:block absolute left-0 bottom-25" />
        <InstallCommand
          className="md:w-489 md:self-end lg:w-678 lg:self-end 2xl:w-840"
          context="hero"
        />
      </div>

      <ArrowDown className="mt-55 mx-auto md:hidden" />
    </>
  );
};

export default HeroSection;
